import React, { FC } from "react"
import { graphql } from "gatsby"
import Layout from "../../layout/layout"
import style from "./distributor.module.scss"
import SEO from "../../components/utilities/seo/SEO"
import DistributorCard from "../../components/utilities/distributor-card/distributorCard"

export const query = graphql`
  {
    allStoryblokEntry(filter: { field_component: { eq: "distributor" } }) {
      edges {
        node {
          name
          content
        }
      }
    }
  }
`
const DistributorPage = ({ data }: any) => {
  const distributors = data.allStoryblokEntry.edges.map(({ node }: any) => ({
    name: node.name,
    ...JSON.parse(node.content),
  }))
  return (
    <Layout title="Distributor" darkHeader className={style.distributor}>
      <SEO
        lang="en"
        title="Become a distributor of the OCD range"
        description="Want to stock the OCD range in your store? Contact us today to become a distributor of OCD products"
        image="https://ocdonline.co.uk/images/ocd/OCD.png"
        keywords="Distributor, OCD"
        meta
      />
      <section>
        <p>We’re odour neutralising specialists, we’re not distributors.</p>
        <p>Thats’ why we partner with the very best wholesalers.</p>
        <p>
          If you are interested in stocking OCD products in your shop, then
          please get in touch with one of our wholesalers via phone or email:
        </p>
        {distributors.map(
          ({ name, email, contact, website, _uid }: any, index: number) => (
            <DistributorCard
              name={name}
              email={email}
              phone={contact}
              website={website}
              key={`distributorCard_${_uid}_${index}`}
            />
          )
        )}
      </section>
    </Layout>
  )
}
export default DistributorPage

interface infoCardProps {
  href: string
  title: string
  subtitle?: string
  external?: boolean
}

export const InfoCard: FC<infoCardProps> = ({
  href,
  title,
  subtitle,
  external,
}) => {
  return (
    <a
      href={href}
      className={style.infoCard}
      rel={external ? "noreferrer noopener" : undefined}
    >
      <h2 className={style.title}>{title}</h2>
      {subtitle && <span className={style.subtitle}>{subtitle}</span>}
    </a>
  )
}
