import React, { FC } from "react"
import style from "./distributorCard.module.scss"
import { InfoCard } from "../../../pages/distributors"

interface distributorCardProps {
  name: string
  email?: string
  phone?: string
  website?: string
}
const stringsToRemove = ["http://", "https://", "www.", /\/$/g]
const strippedStringOfArray = (
  originalString: string,
  elementsToRemove: (string | RegExp)[]
) => {
  let strippedString = originalString
  elementsToRemove.forEach(str => {
    strippedString = strippedString.replace(str, "")
  })
  return strippedString
}
const DistributorCard: FC<distributorCardProps> = ({
  name,
  email,
  phone,
  website,
}) => {
  return (
    <div className={style.container}>
      <h2>{name}</h2>
      {email && (
        <InfoCard title="Email" subtitle={email} href={`mailto:${email}`} />
      )}
      {phone && (
        <InfoCard title="Call" subtitle={phone} href={`tel:${phone}`} />
      )}
      {website && (
        <InfoCard
          title="Visit Online"
          subtitle={strippedStringOfArray(website, stringsToRemove)}
          href={website}
          external
        />
      )}
    </div>
  )
}

export default DistributorCard
